import { useState } from "react"
import { Select, Tag } from "antd"

export const PopularitySelect = ({ onSelect, value }) => {

    const [_value, _setValue] = useState(value)

    const options = [
        {
            label: <><Tag color="green">High</Tag> 16 or higher sold per month</>,
            value: 'high'
        },
        {
            label: <><Tag color="yellow">Medium</Tag>4-15 sold items per month</>,
            value: 'medium'
        },
        {
            label: <><Tag color="red">Low</Tag>1-3 sold items per month</>,
            value: 'low'
        },
        {
            label: <><Tag color="grey">Not Sold</Tag></>,
            value: 'not_sold'
        },
    ]
   
    return <Select
        allowClear
        onClear={() => {
            _setValue(undefined)
            onSelect('')
        }}
        value={_value} placeholder="Select Popularity"
        options={options || []}
        onSelect={(value) => {
            _setValue(value)
            onSelect(value)
        }} />
}