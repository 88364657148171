import { Space } from 'antd'
import { PageContent } from '../components/page-content/page-content'
import { ProductsTable } from "../components/products-table/products-table"
import { useQuery } from '../hooks/use-query'
import { ProductsFilters } from '../components/products-filters/products-filters'
import { useState } from 'react'

export const Products = () => {
    const query = useQuery()
    const supplierId = query.get('supplierId')
    const [queryParams, setQueryParams] = useState({
        ...(supplierId && { supplierId })
    })

    const handleOnFiltersChange = (newFilters) => {
        setQueryParams({ ...queryParams, ...newFilters })
    }

    return (
        <PageContent
            title='Supplier Products'
            >
            <Space direction='vertical' size='large'>
                <ProductsFilters onFiltersChange={handleOnFiltersChange} />
                <ProductsTable params={queryParams} />
            </Space>
        </PageContent>)


}