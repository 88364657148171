import axios from 'axios';

// Define your API base URL
const BASE_URL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:3000';

// const accessToken = localStorage.getItem('access_token')

// Create an Axios instance with a custom config
export const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',  
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // Call your logout function here
      logoutUser();
      window.location.href = '/login'
    }
    return Promise.reject(error);
  }
);

apiClient.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('access_token')

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});


// Define your logoutUser function here
const logoutUser = () => {
  // Clear user authentication (e.g., remove tokens, clear session)
  // Redirect to the login page or perform any necessary cleanup
  localStorage.removeItem('access_token');
  // You might want to redirect the user to the login page here.
};