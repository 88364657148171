import React, { useState } from "react";
import logo from "./nutri-logo.svg";
import { Layout, Tag, Avatar, Dropdown, Button, Modal, Divider, Space } from "antd";
import { Menu } from "./components/menu/menu";
import { Sidebar } from "./components/sidebar/sidebar";
import { APP_VERSION } from './config';
import { ProtectedRoutes } from "./routes";
import { BrowserRouter } from "react-router-dom";
import { useAuth } from "./contexts/auth-context";
import { UserAvatar } from "./components/user-avatar/user-avatar";

export const AppLayout = () => {
  const [userModalVisible, setUserModalVisible] = useState(false)
  const {signOut, user} = useAuth()

  return (
    <Layout hasSider>
      <BrowserRouter>
        <Sidebar>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100vh' }}>
            <div style={{ textAlign: 'center' }} >
              <div style={{ height: '65px' }}>
                <img src={logo} className="App-logo" alt="logo" />
              </div>
              <Tag>v{APP_VERSION}</Tag>
            </div>
            <Menu />
            <div style={{ padding: '10px', textAlign: "center" }}>
              <UserAvatar user={user} onClick={()=> setUserModalVisible(true)}/>
              <Modal footer={null} open={userModalVisible} onCancel={() => setUserModalVisible(false)} title={'Logged in as'}>
                <div style={{ textAlign: 'center' }}>
                  <Space direction="vertical">
                   <UserAvatar user={user}/>
                    <div>
                      {user?.name} {user?.lastname}
                    </div>
                  </Space>
                  <Divider />
                  <div>
                    <Button onClick={()=> signOut()}>Logout</Button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </Sidebar>
        <Layout
          className="site-layout"
          style={{
            marginLeft: 200,
          }}
        >
          <ProtectedRoutes />
        </Layout>
      </BrowserRouter>
    </Layout>

  );
};
