import { Space, Table, Tag } from 'antd'
import { PageContent } from '../components/page-content/page-content'
import { ProductsFilters } from '../components/products-filters/products-filters'
import { useEffect, useState } from 'react'
import { apiClient } from '../api/client'
import { ProductThumbnail } from '../components/product-thumbnail/product-thumbnail'


export const SmartPucrchaseOrder = () => {
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [selectedDate, setSelectedDate] = useState('lastWeek')


    const getDate = (selectValue) => {
        const date = new Date()
        if (selectValue === 'lastDay') {
            date.setDate(date.getDate() - 1)
        }
        if (selectValue === 'lastWeek') {
            date.setDate(date.getDate() - 7)
        }
        if (selectValue === 'lastMonth') {
            date.setDate(date.getDate() - 30)
        }
        return date
    }


    const handleOnFiltersChange = (newFilters) => {
        console.log(newFilters)
    }

    const fetchProductsForPurchaseOrder = async (date) => {
        setLoading(true)
        const response = await apiClient.get(`/products/get-trending-products?date=${date}`)
        setProducts(response.data)
        setLoading(false)
    }


    useEffect(()=> {
        fetchProductsForPurchaseOrder(getDate(selectedDate))
    },[])

    const columns = [
        {
            width: 50,
            render: (row) => <ProductThumbnail imageUrl={row.product.imageUrl} />
        },
        { title: 'Supplier', width: 100, ellipsis : true, render: (row) => <>{row.product.supplier?.name}</> },
        { title: 'Brand',width: 100, ellipsis : true, render: (row) => <>{row.product.brand}</> },
        { title: 'Name',width: 100, ellipsis : true, render: (row) => <>{row.product.product}</> },
        { title: 'EAN', ellipsis : true, render: (row) => <>{row.product.ean}</> },      
        { title: '', align: 'right', width: 100, render: (row) => <Tag color="green">Ordered {row.count} times</Tag> },
        { title: 'Warehouse stock', width: 100, align:'right', render: (row) => <>{row.product.warehouse_stock}</> },
    ]

    return (
        <PageContent
            title='Smart purchase order'
            >
            <Space direction='vertical' size='large'>
                <ProductsFilters onFiltersChange={handleOnFiltersChange} />
                <Table loading={loading} size="small" scroll={{ y: 700, x: 'auto' }} dataSource={products} columns={columns} pagination={false} />
            </Space>
        </PageContent>)


}