import React, { createContext, useContext, useState } from 'react';

// Create a context to manage the theme state
const ThemeContext = createContext();

// Custom hook to access the theme and toggle function
export const useTheme = () => {
  return useContext(ThemeContext);
};

// ThemeProvider component to wrap your entire application
export const ThemeProvider = ({ children }) => {
    
  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true' ? true : false);
  const [compactMode, setCompactMode] = useState(localStorage.getItem('compactMode') === 'true' ? true : false);
  
  const toggleDarkMode = () => {
    localStorage.setItem('darkMode', !darkMode)
    setDarkMode(!darkMode);
  };

  const toggleCompactMode = () => {
    localStorage.setItem('compactMode', !compactMode)
    setCompactMode(!compactMode);
  };

  return (
    <ThemeContext.Provider value={{ darkMode, compactMode, toggleDarkMode, toggleCompactMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
