import { useEffect, useState } from "react"
import { apiClient } from "../api/client"
import { Button, Checkbox, Form, Modal, Table, Tag } from 'antd'
import { objectToQueryString } from "../utilities/convert-to-query-string"
import { PageContent } from '../components/page-content/page-content'
import { useNavigate } from "react-router-dom"
import { RightOutlined } from '@ant-design/icons';
import { StatusTag } from "../components/status-tag/status-tag"
import { SupplierSelect } from "../components/supplier-select/supplier-select"
import dayjs from "dayjs"


export const PurchaseOrders = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [purchaseOrders, setPurchaseOrders] = useState()
    const [selectSupplier, setSelectedSupplier] = useState()
    const [autoGenerateLineItems, setAutoGenerateLineItems] = useState()

    const [showNewPurchaseOrder, setShowNewPurchaseOrder] = useState(false)
    
    const [pageOptions, setPageOptions] = useState({
        pageSize: 50,
        current: 1,
        total: 0,
    })

    const handleOnCreatePurchaseOrder = async () => {
        setLoading(true);
        const purchaseOrder = await apiClient.post('purchase-orders', { reference: '', supplierId: selectSupplier, autoGenerateLineItems })
        setLoading(false);  
        if (purchaseOrder.data.id) {
            navigate(`/purchase-orders/${purchaseOrder.data.id}`)
        }
        
    }


    const columns = [
        {
            title: 'Status',
            width: 100,
            render: (row) => <StatusTag status={row.status} />

        }, {
            title: 'id',
            width: 50,
            dataIndex: 'id',
        }, {
            title: 'Reference',
            dataIndex: 'reference',
            width: 150,
        }, {
            title: 'Supplier',
            width: 100,
            render: (row) => row.supplier?.name || ''

        }, {
            title: 'Create At',
            width: 300,
            render: (row) => <Tag>{dayjs(row.createdAt).format('YYYY-MM-DD')}</Tag>

        }, {
            align: 'right',
            width: 30,
            render: (row) => <RightOutlined onClick={() => navigate(`/purchase-orders/${row.id}`)} />

        }]

    const fetchData = () => {
        const params = {
            pageSize: pageOptions.pageSize,
            pageNumber: pageOptions.current
        }
        setLoading(true)
        apiClient.get(`/purchase-orders?${objectToQueryString(params)}`)
            .then(res => {
                setPurchaseOrders(res.data?.data)
                setPageOptions({ ...pageOptions, total: res.data.count })
            })
            .finally(() => setLoading(false))
    }

    const handleTableChange = (nexPagination) => {
        setPageOptions({ ...pageOptions, current: nexPagination.current })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line 
    }, [pageOptions.current])

    return (
        <PageContent
            title='Purchase Orders'
            subTitle={`${pageOptions.total} Purchase Orders`}
            actions={
            <>
            <Button type="primary" onClick={() => { setShowNewPurchaseOrder(true) }}>Create New</Button>
            </>}>
            <Table
                size="small"
                rowKey={'id'}
                loading={loading}
                dataSource={purchaseOrders}
                columns={columns}
                onChange={handleTableChange}
                pagination={{ pageSize: pageOptions.pageSize, total: pageOptions.total, current: pageOptions.current, showSizeChanger: false }}
                scroll={{ y: '60vh' }} />

            <Modal
                open={showNewPurchaseOrder}
                title="New Purchase Order"
                onOk={handleOnCreatePurchaseOrder}
                okButtonProps={{ disabled: !selectSupplier, loading: loading }}
                onCancel={() => {
                    setShowNewPurchaseOrder(false)
                }}>
                <div >
                    <Form layout="vertical" colon={false}>
                        <Form.Item label="Supplier">
                            <SupplierSelect onSelect={(value) => setSelectedSupplier(value)} shouldDisableInActive={true} />
                        </Form.Item>
                        <Form.Item >
                            <Checkbox onChange={(e) => setAutoGenerateLineItems(e.target.checked)}>Auto Generate Line-items</Checkbox>
                        </Form.Item>
                    </Form>
                </div>

            </Modal>
        </PageContent>)


}