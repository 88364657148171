import React, { createContext, useContext, useEffect, useState } from 'react';
import { apiClient } from '../api/client';


const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {

    const access_token = localStorage.getItem('access_token')
    const [isAuthenticated, setIsAuthenticated] = useState(access_token ? true : false);
    const [user, setUser] = useState(null)


    useEffect(()=>{
        const loggedInUser = localStorage.getItem('username');
        if(loggedInUser){
            getUser(loggedInUser)
        }
    },[])

    const getUser = async(username) =>{
        try{
            const response = await apiClient.get(`/users?username=${username}`)
            setUser(response.data)
        }   
        catch(e){
            console.error(e)
        }
    }

    const signIn = async (username, password) => {
        try {
            const response = await apiClient.post('/auth/login', { username, password });
            if (response.data.access_token) {
                localStorage.setItem('access_token', response.data.access_token)
                localStorage.setItem('username', username)
                setIsAuthenticated(true)
                return true
            }
        }
        catch (e) {
            return false
        }

    }

    const signOut = async () => {
        localStorage.removeItem('access_token')
        setIsAuthenticated(false)
    }


    return (
        <AuthContext.Provider value={{ signIn, signOut, isAuthenticated, user }}>
            {children}
        </AuthContext.Provider>
    );
};
