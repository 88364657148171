import {Menu as AntdMenu} from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    AppstoreOutlined,
    FileSyncOutlined,
    SettingOutlined,
    ShopOutlined,
    ExclamationCircleOutlined,
    ContactsOutlined
} from '@ant-design/icons';


export const Menu = () => {
    const navigate = useNavigate()
    const {pathname} = useLocation()
    console.log(pathname)

    const menuItems = [
        {key:'/',  label: 'Home', icon:<AppstoreOutlined/>, onClick: ()=> navigate('/')  },
        {key:'/suppliers',label: 'Suppliers', icon: <ContactsOutlined/>, onClick: ()=> navigate('/suppliers')  },
        {key:'/products',label: 'Products', icon: <FileSyncOutlined/>, onClick: ()=> navigate('/products')  },
        {key:'/purchase-orders',label: 'Purchase orders',icon: <ShopOutlined/>, onClick: () => navigate('/purchase-orders')  },
        // {key:'/smart-purchase-order',label: 'Smart Purchase order',icon: <ShopOutlined/>, onClick: () => navigate('/smart-purchase-order')  },
        {key:'/error-logs',label: 'Error Logs', icon:<ExclamationCircleOutlined />, onClick: () => navigate('/error-logs')  },
        {key:'/settings',label: 'Settings', icon:<SettingOutlined/>, onClick: () => navigate('/settings')  }
    ]

    return <AntdMenu selectedKeys={[pathname]}  mode='vertical' items={menuItems} />
}