import { Card, Layout, Space } from 'antd';
const { Content } = Layout;



export const PageContent = ({ title, subTitle, children, actions = [] }) => {
    return (
        <div style={{background: 'white', height: '100vh', padding: '40px', paddingTop: '10px', borderLeft: '1px solid whitesmoke'}} >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0' }}>
                <div>
                    <h2>{title}</h2>
                    <p className='text-light text-sm'>{subTitle}</p>
                </div>
                <div>
                    <Space>
                        {actions}
                    </Space>
                </div>
            </div>

            <Content>
                {children}
            </Content>
        </div>
    )
}
