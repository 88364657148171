import { useEffect, useState } from "react"
import { apiClient } from "../../api/client"
import { objectToQueryString } from "../../utilities/convert-to-query-string"
import { Input, Table, Button, Modal, Space, Tag } from "antd"
import { ProductThumbnail } from "../product-thumbnail/product-thumbnail"
import {
    DeleteOutlined, StarOutlined,
} from '@ant-design/icons';
import { ProductsFilters } from "../products-filters/products-filters"

const QuantityInput = ({ disabled, initialValue, onBlur }) => {
    const [value, setValue] = useState(initialValue)

    return <Input
        value={value}
        disabled={disabled}
        min={0}
        type="number"
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) => {
            if (value !== initialValue) {
                onBlur(e.target.value)
            }
        }} />
}

export const PurchaseOrderSuggestions = ({ purchaseOrderId, disabled }) => {

    const [loading, setLoading] = useState(false)
    const [lineItems, setLineItems] = useState([])
    const [lineItemUpdateParams, setLineItemUpdateParams] = useState();

    const [pageOptions, setPageOptions] = useState({
        pageSize: 50,
        current: 1,
        total: 0,
        showSizeChanger: false
    })

    const updateLineItem = async () => {
        await apiClient.put('/purchase-order-line-items', lineItemUpdateParams)
    };

    const fetchData = (filters) => {
        const params = {
            pageSize: pageOptions.pageSize,
            pageNumber: pageOptions.current,
            purchaseOrderId,
            ...filters
        }
        setLoading(true)

        apiClient.get(`/purchase-order-line-items?${objectToQueryString(params)}`) // TEMP
            .then(res => {
                setLineItems(res.data.data)
                setPageOptions({ ...pageOptions, total: res.data.count })
            })
            .finally(() => setLoading(false))
    }

    const handleTableChange = (nexPagination) => {
        setPageOptions({ ...pageOptions, current: nexPagination.current })
    }


    const handleConfirmRemove = async (id) => {
        await apiClient.delete(`/purchase-order-line-items/${id}`).then((
            fetchData()
        ))
    }

    const handleRemoveItem = (id) => {
        Modal.confirm({
            title: 'Delete Purchase order line item',
            title: 'Are you sure you want to remove this item?',
            onOk: () =>  handleConfirmRemove(id),
            okCancel: () => console.log('cancelled')
        })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageOptions.current])

    useEffect(() => {
        if (lineItemUpdateParams?.id) {
            updateLineItem()
        }
        // eslint-disable-next-line 
    }, [lineItemUpdateParams])


    const columns = [{
        width: 40,
        render: (row) => <ProductThumbnail imageUrl={row.imageUrl} />

    }, {
        title: 'Brand',
        dataIndex: 'brand',
        width: 150,

    }, {
        title: 'Product',
        width: 250,
        ellipsis: true,
        render: (row) => <>{row.popularity === 'high' &&  <Tag color="green" icon={<StarOutlined/>}/>} {row.product}</>

    }, {
        title: 'SKU',
        width: 150,
        dataIndex: 'sku',

    }, {
        title: 'EAN',
        width: 150,
        dataIndex: 'ean',

    }, {
        title: 'Quantity',
        width: 70,
        render: (row) => <QuantityInput disabled={disabled} initialValue={row.quantity} onBlur={(newQuantity) => setLineItemUpdateParams({ id: row.id, quantity: newQuantity })} />
    }, {
        align: 'right',
        width: 50,
        render: (row) => <Button type="link" onClick={() => handleRemoveItem(row.id)} icon={<DeleteOutlined />} />
    }]

    return <Space direction="vertical" size="large">
        <ProductsFilters
            options={{ supplier: false,  stockStatus: false, orderRange:false, popularity: false }}
            onFiltersChange={(newFilters) => {
                setPageOptions({ ...pageOptions, current: 1 })
                fetchData(newFilters)
            }} />
        <Table
            size="small"
            rowKey={'id'}
            loading={loading}
            dataSource={lineItems}
            columns={columns}
            onChange={handleTableChange}
            pagination={pageOptions}
            scroll={{ y: '60vh' }} />
    </Space>

}
