import { BrowserRouter } from "react-router-dom"
import { Routes } from "./routes"

export const LoginLayout = () => {
    return (
        <div className="login-container" >
            <BrowserRouter>
                <Routes/>
            </BrowserRouter>
        </div>
    )
}
