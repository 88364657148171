import { Avatar } from "antd";

const getInitials=(firstName, lastName) => {
    
    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = lastName.charAt(0).toUpperCase();
  
    const initials = firstInitial + lastInitial;
    return initials;
  }

export const UserAvatar = ({ user, onClick }) => {
    if(!user){
        return null
    }
    return <Avatar onClick={onClick} style={{ backgroundColor: '#515151', cursor:'pointer', verticalAlign: 'middle' }} size="large" gap={10}>
        {getInitials(user.name, user.lastname ) || 'N/A'}
    </Avatar>
}