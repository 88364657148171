import {
    Route,
    Routes as ReactRoutes,
    Navigate,
} from "react-router-dom";
import { Home } from "./pages/home";
import { Products } from "./pages/products";
import { PurchaseOrders } from "./pages/purchase-orders";
import { CreatePurchaseOrder } from "./pages/create-purchase-order";
import { Settings } from "./pages/settings";
import { Login } from "./pages/login";
import { ErrorLogs } from "./pages/error-logs";
import { Suppliers } from "./pages/suppliers";
import { SmartPucrchaseOrder } from "./pages/smart-purchase-order";


export const Routes = () => {
    return (
        <ReactRoutes>
            <Route path="/login"  element={<Login />} />
            <Route path="/*"  element={<Navigate to="/login" replace />} />
        </ReactRoutes>
    );
}
export const ProtectedRoutes = () => {

    return (
        <ReactRoutes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/smart-purchase-order" element={<SmartPucrchaseOrder/>} />
            <Route path="/purchase-orders" element={<PurchaseOrders/>} />
            <Route path="/purchase-orders/:id" element={<CreatePurchaseOrder/>} />
            <Route path="/error-logs" element={<ErrorLogs/>} />
            <Route path="/suppliers" element={<Suppliers/>} />
            <Route path="/settings" element={<Settings/>} />
        </ReactRoutes>
    );
}