import { useEffect, useState } from "react"
import { Table, Tag } from 'antd'
import { apiClient } from "../../api/client"
import { objectToQueryString } from "../../utilities/convert-to-query-string"
import { ProductThumbnail } from "../product-thumbnail/product-thumbnail"
import { Alert, Space } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';
import { StarOutlined } from '@ant-design/icons'

dayjs.extend(utc)

export const ProductsTable = ({ params, canSelectRows, onUpdateSelectedKeys }) => {

    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState()
    const [lastSync, setLastSync] = useState()

    const [pageOptions, setPageOptions] = useState({
        pageSize: 200,
        current: 1,
        total: 0,
    })
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const fetchData = (queryParams) => {

        setLoading(true)
        apiClient.get(`/products?${objectToQueryString(queryParams)}`)
            .then(res => {
                setProducts(res.data?.data)
                setPageOptions({ ...pageOptions, total: res.data.count })
                setLastSync(res.data.lastSync)
            }).catch((e) => {
                console.log(e)
            })
            .finally(() => setLoading(false))
    }


    const handleTableChange = (nexPagination) => {
        setPageOptions({ ...pageOptions, current: nexPagination.current })
    }


    const handleOnRowSelect = (values) => {
        setSelectedRowKeys(values)
        const selectedProducts = []
        for(const value of values){
            const product = products.find((p) => p?.id === value)
            if(product){
                selectedProducts.push(product)
            }
        }

        onUpdateSelectedKeys(values, selectedProducts)     
        
        
    }
    useEffect(() => {
        fetchData({ pageSize: pageOptions.pageSize, pageNumber: pageOptions.current, ...params })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageOptions.current])

    useEffect(() => {
        fetchData({ pageSize: pageOptions.pageSize, pageNumber: 1, ...params })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])



    const columns = [{
        width: 40,
        render: (product) => <ProductThumbnail imageUrl={product.imageUrl} />

    }, {
        title: 'Supplier',
        width: 100,
        render: (product) => product.supplier?.name
    }, {
        title: 'Brand',
        dataIndex: 'brand',
        width: 100,
        ellipsis: true,
    }, {
        title: 'Product',
        width: 400,
        ellipsis: true,
        render: (row) => {
            if(row.popularity === 'high'){
                return <> <Tag color="green" icon={<StarOutlined/>}/> {row.product}</>
            }
            if(row.popularity === 'medium'){
                return <> <Tag color="yellow" icon={<StarOutlined/>}/> {row.product}</>
            }
            if(row.popularity === 'low'){
                return <> <Tag color="red" icon={<StarOutlined/>}/> {row.product}</>
            }
            else {
                return <>{row.product}</>
            }
        }
    }, {
        title: 'SKU',
        dataIndex: 'sku',
        width: 90,
        ellipsis: true,

    }, {
        title: 'EAN',
        dataIndex: 'ean',
        width: 110,
        ellipsis: true,

    }, {
        title: 'Exp. Date',
        render: (row) => row.expiration_date ? <Tag>{dayjs(row.expiration_date).format('YYYY-MM-DD')}</Tag> : 'N/A',
        width: 100,

    }, {
        title: 'Price (EUR)',
        dataIndex: 'price',
        width: 80

    }, {
        title: 'Supplier Stock',
        dataIndex: 'quantity',
        width: 70,
        align: 'right'
    }, {
        title: 'Warehouse Stock',
        dataIndex: 'warehouse_stock',
        width: 70,
        align: 'right'
    }, {
        title: 'Reorder Quantity',
        dataIndex: 'minimumReorderAmount',
        width: 70,
        align: 'right'
    },]

    const rowSelection = {
        hideSelectAll: false,
        selectedRowKeys,
        onChange: handleOnRowSelect,
    }

    const optionalProps = {
        ...(canSelectRows && rowSelection ? { rowSelection } : {})
    };

    return (
        <Space direction="vertical">
            <Alert message={`Synced last time at : ${(dayjs.utc(lastSync?.createdAt).local().format('YYYY-MM-DD HH:mm'))}`} type="info" />
            <Table
                size="small"
                rowKey={'id'}
                loading={loading}
                dataSource={products}
                columns={columns}
                onChange={handleTableChange}
                pagination={{ pageSize: pageOptions.pageSize, total: pageOptions.total, current: pageOptions.current, showSizeChanger: false, disabled: loading }}
                scroll={{ y: '55vh'}}
                {...optionalProps}
            />
        </Space>)
}