import { Layout } from 'antd';

const { Sider } = Layout;


export const Sidebar = ({ children }) => {
    return <Sider theme='light'
        style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
        }}
    >
        {children}
    </Sider>
}