import { useEffect, useState } from "react"
import { apiClient } from "../../api/client"
import { Select, Tag } from "antd"

export const SupplierSelect = ({ onSelect, value, shouldDisableInActive = false }) => {
    const [suppliers, setSupppliers] = useState()
    const [_value, _setValue] = useState(value)
    const getLabel = (supplier) => supplier.is_active ? supplier.name : <> {supplier.name} <Tag color="yellow">In-Active</Tag> </>
    const options = suppliers?.map(supplier => ({ label: getLabel(supplier), value: supplier.id, disabled: shouldDisableInActive && !supplier.is_active }))

    const fetchSuppliers = async () => {
        const response = await apiClient.get('/suppliers')
        setSupppliers(response.data)
    }

    useEffect(() => {
        fetchSuppliers()
    }, [])


    return <Select
        allowClear
        onClear={() => {
            _setValue(undefined)
            onSelect('')
        }}
        value={_value} placeholder="Select supplier"
        options={options || []}
        onSelect={(value) => {
            _setValue(value)
            onSelect(value)
        }} />
}