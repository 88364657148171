import { useEffect, useState } from "react";
import { Button, Card, Divider, Select, Space, Statistic, Table, Tag } from 'antd';
import { apiClient } from "../api/client";
import { ProductThumbnail } from "../components/product-thumbnail/product-thumbnail";
import { useNavigate } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons';

const RangeValue = 'LastDay' | 'LastWeek' | 'LastMonth'

const SupplierProductsView = ({ suppliers }) => {
    const navigate = useNavigate()

    const columns = [

        {
            render: (row) => <>{row.supplier.name}</>
        },
        {
            align: 'right',
            render: (row) => <>
                <Tag color="purple"><Statistic valueStyle={{ fontSize: '12px' }} value={row.productsCount} /></Tag>
                {row.supplier.is_active ? <Tag color="green">Active</Tag> : <Tag color="yellow">In-active</Tag>}
            </>
        },

        {
            align: 'right',
            render: (row) => <><Button onClick={() => navigate(`/products?supplierId=${row.supplier.id}`)} type="link" ><Space>See Products <RightOutlined /></Space></Button></>
        },

    ]
    return <Table size="small" showHeader={false} dataSource={suppliers} columns={columns} pagination={false} />
}

const ProductsView = ({ products, loading }) => {
    const columns = [
        {
            width: 50,
            render: (row) => <ProductThumbnail imageUrl={row.product.imageUrl} />

        },
        { title: 'Supplier', width: 150, ellipsis : true, render: (row) => <>{row.product.supplier?.name}</> },
        { title: 'Name', width: 200, ellipsis : true, render: (row) => <>{row.product.product}</> },
        { title: 'EAN', width: 150, ellipsis : true, render: (row) => <>{row.product.ean}</> },      
        { title: '', align: 'right', width: 100, render: (row) => <Tag color="green">Ordered {row.count} times</Tag> },
        { title: 'Warehouse stock', align:'right', render: (row) => <>{row.product.warehouse_stock}</> },
    ]
    return <Table loading={loading} size="small" scroll={{ y: 400, x: 'auto' }} dataSource={products} columns={columns} pagination={false} />
}

export const Home = () => {

    const navigate = useNavigate()
    const [stats, setStats] = useState()
    const [loading, setLoading] = useState(false)
    const [topSellingProducts, setTopSellingProducts] = useState(false)
    const [loadingTopSellingProducts, setLoadingTopSellingProducts] = useState(false)
    const [selectedDate, setSelectedDate] = useState('lastWeek')

    const fetchStats = async () => {
        setLoading(true)
        const response = await apiClient.get('/statistics')
        setStats(response.data)
        setLoading(false)
    }

    const fetchTopSellingProducts = async (date) => {
        setLoadingTopSellingProducts(true)
        const response = await apiClient.get(`/products/get-trending-products?date=${date}`)
        setTopSellingProducts(response.data)
        setLoadingTopSellingProducts(false)
    }

    useEffect(() => {
        fetchStats()
        fetchTopSellingProducts(getDate(selectedDate)) }, [])


    const getDate = (selectValue) => {
        const date = new Date()
        if (selectValue === 'lastDay') {
            date.setDate(date.getDate() - 1)
        }
        if (selectValue === 'lastWeek') {
            date.setDate(date.getDate() - 7)
        }
        if (selectValue === 'lastMonth') {
            date.setDate(date.getDate() - 30)
        }
        return date
    }

    const dateOptions = [
        { label: "Last Day", value: 'lastDay' },
        { label: "Last Week", value: 'lastWeek' },
        { label: "Last Month", value: 'lastMonth' },
    ]

    return (
        <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '30px', flexWrap: 'wrap',
            alignItems: 'stretch', padding: '30px', flexWrap: 'wrap'
        }}>
            <div style={{ flex: '1', display: 'flex', gap: '20px'}}>
                <Card loading={loading} style={{ flex: '1', textAlign: 'center' }} title="Suppliers">
                    <Statistic title="Total Products" value={stats?.products} loading={loading} />
                    <Divider />
                    <SupplierProductsView suppliers={stats?.suppliers} />
                </Card>
                
                <Card loading={loading} style={{ flex: '1', textAlign: 'center' }} title="Today's Errors">
                    <Statistic title={'Errors'} value={stats?.todaysErrors?.length} />
                    <Button onClick={() => navigate(`/error-logs`)} type="link" ><Space>See Errors <RightOutlined /></Space></Button>
                </Card>
            </div>

            <div style={{ display: 'flex', flex: '1', gap: '20px' }}>
            <Card
                    style={{ flex: '3', textAlign: 'center' }}
                    title={
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div>Trending Products</div>
                            <Select
                                value={selectedDate}
                                style={{ width: '200px' }}
                                options={dateOptions} 
                                onChange={(value) => {
                                    setSelectedDate(value)
                                    fetchTopSellingProducts(getDate(value))} 
                                 }
                                    
                                    />
                        </div>}
                >
                    <Statistic title="Total" value={topSellingProducts.length} loading={loading} />
                    <ProductsView loading={loadingTopSellingProducts} products={topSellingProducts} />
                    
                </Card>
            </div>
        </div>)

}