import { Form, Switch } from "antd"
import { PageContent } from "../components/page-content/page-content"
import FormItem from "antd/es/form/FormItem"
import { useTheme } from "../contexts/theme-context"

export const Settings = () => {
    const { toggleDarkMode, toggleCompactMode, darkMode, compactMode } = useTheme();



    return (
        <PageContent title={'Settings'} subTitle={'Here you can manage the settings'}>
            <section>
                <Form
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 14 }}
                    labelAlign="left"
                    colon={false}>
                    <FormItem label="Dark mode">
                        <Switch checked={darkMode} onChange={(checked) => toggleDarkMode(checked)} />
                    </FormItem>
                    <FormItem label="Compact mode">
                        <Switch checked={compactMode} onChange={(checked) => toggleCompactMode(checked)} />
                    </FormItem>
                </Form>
            </section>
        </PageContent>
    );
};
