import { Button, Form, Input } from 'antd';
import { useAuth } from '../contexts/auth-context';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const { signIn } = useAuth()
  const navigate = useNavigate()

  const onFinish = async (values) => {
    const isSignedIn = await signIn(values.username, values.password)
    if(isSignedIn){
      navigate('/', {replace: true} )
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <Form
      className='login-form'
      layout='vertical'
      name="basic"
      
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <h4 style={{textAlign:'center'}}>Login to Nutri Operations</h4>
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input size='large' />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password size='large'/>
      </Form.Item>

      <Form.Item
        style={{textAlign:'right'}}
      >
        <Button type="primary" htmlType="submit">
          Login
        </Button>
      </Form.Item>
    </Form>
  )
}



