import { Space } from 'antd'
import { PageContent } from '../components/page-content/page-content'
import { useQuery } from '../hooks/use-query'
import { useState } from 'react'
import { ErrorLogsTable } from '../components/error-logs-table/error-logs-table'

export const ErrorLogs = () => {
    const query = useQuery()
    const supplierId = query.get('supplierId')
    const [queryParams, setQueryParams] = useState({
        ...(supplierId && { supplierId })
    })

    const handleOnFiltersChange = (newFilters) => {
        setQueryParams({ ...queryParams, ...newFilters })
    }

    return (
        <PageContent
            title='Error Logs'
            >
            <Space direction='vertical' size='large'>
                {/* <ProductsFilters onFiltersChange={handleOnFiltersChange} /> */}
                <ErrorLogsTable params={queryParams} />
            </Space>
        </PageContent>)


}