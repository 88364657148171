import { useState } from "react"
import { Select, Tag } from "antd"

export const SimpleRangeSelect = ({ onSelect, value }) => {

    const [_value, _setValue] = useState(value)


    const getDate = (selectValue) => {
        const date = new Date()
        if (selectValue === 'lastDay') {
            date.setDate(date.getDate() - 1)
        }
        if (selectValue === 'lastWeek') {
            date.setDate(date.getDate() - 7)
        }
        if (selectValue === 'lastMonth') {
            date.setDate(date.getDate() - 30)
        }
        return date
    }

    const options = [
        { label: "Last Day", value: 'lastDay' },
        { label: "Last Week", value: 'lastWeek' },
        { label: "Last Month", value: 'lastMonth' },
    ]

   
    return <Select
        allowClear
        onClear={() => {
            _setValue(undefined)
            onSelect('')
        }}
        value={_value} placeholder="Select Popularity"
        options={options || []}
        onSelect={(value) => {
            _setValue(value)
            onSelect(getDate(value))
        }} />
}