import { useState } from "react"
import { Select, Tag } from "antd"

export const StockStatusSelect = ({ onSelect, value }) => {

    const [_value, _setValue] = useState(value)

    const options = [
        { label: <Tag color="green">In stock</Tag>, value: 'in_stock' },
        { label: <Tag color="red">Out of stock</Tag>, value: 'out_of_stock' },
    ]

   
    return <Select
        allowClear
        onClear={() => {
            _setValue(undefined)
            onSelect('')
        }}
        value={_value} placeholder="Select stock status"
        options={options || []}
        onSelect={(value) => {
            _setValue(value)
            onSelect(value)
        }} />
}