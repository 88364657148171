import { Form, Input } from "antd"
import { SupplierSelect } from "../supplier-select/supplier-select"
import { useQuery } from "../../hooks/use-query"
import { SearchOutlined } from '@ant-design/icons';
import './products-filters.css'
import { PopularitySelect } from "../popularity-select/popularity-select";
import { SimpleRangeSelect } from "../simple-range-select/simple-range-select";
import { StockStatusSelect } from "../stock-status-select/stock-status-select";

const defaultOptions = {
    supplier: true,
    brand: true,
    productName: true,
    sku: true,
    ean: true,
    popularity: true,
    orderRange: true,
    stockStatus: true
}

export const ProductsFilters = ({ onFiltersChange, options }) => {
    const query = useQuery()

    const mergedOptions = { ...defaultOptions, ...options };

    return (
        <Form layout="inline" className="filters-container">
            {!!mergedOptions.supplier &&
                <Form.Item className="filter-item" >
                    <label>Supplier</label>
                    <SupplierSelect value={Number(query.get('supplierId')) || undefined} onSelect={(value) => { onFiltersChange({ supplierId: value }) }} />
                </Form.Item>
            }
            {!!mergedOptions.brand &&
                <Form.Item className="filter-item">
                    <label>Brand</label>
                    <Input allowClear addonBefore={<SearchOutlined />} placeholder="Search for brand" onChange={(e) => { onFiltersChange({ brand: e.target.value }) }} />
                </Form.Item>
            }
            {!!mergedOptions.productName &&
                <Form.Item className="filter-item">
                    <label>Product Name</label>
                    <Input allowClear addonBefore={<SearchOutlined />} placeholder="Search for product name" onChange={(e) => { onFiltersChange({ productName: e.target.value }) }} />
                </Form.Item>
            }
            {!!mergedOptions.sku &&
                <Form.Item className="filter-item">
                    <label>SKU</label>
                    <Input allowClear addonBefore={<SearchOutlined />} placeholder="Search for SKU" onChange={(e) => { onFiltersChange({ sku: e.target.value }) }} />
                </Form.Item>
            }
            {!!mergedOptions.ean &&
                <Form.Item className="filter-item">
                    <label>EAN</label>
                    <Input allowClear addonBefore={<SearchOutlined />} placeholder="Search for EAN" onChange={(e) => { onFiltersChange({ ean: e.target.value }) }} />
                </Form.Item>
            }
            {!!mergedOptions.popularity &&
                <Form.Item className="filter-item">
                    <label>Popularity</label>
                    <PopularitySelect onSelect={(value) => { onFiltersChange({ popularity: value }) }} />
                </Form.Item>
            }
            {!!mergedOptions.orderRange &&
                <Form.Item className="filter-item">
                    <label>Order Range</label>
                    <SimpleRangeSelect onSelect={(value) => { onFiltersChange({ orderRange: value }) }} />
                </Form.Item>
            }
            {!!mergedOptions.stockStatus &&
                <Form.Item className="filter-item">
                    <label>Stock Status</label>
                    <StockStatusSelect onSelect={(value) => { onFiltersChange({ stockStatus: value }) }} />
                </Form.Item>
            }
        </Form>
    )
}