import { ConfigProvider, theme } from "antd"
import { AppLayout } from "./app-layout"
import { useAuth } from "./contexts/auth-context"
import { LoginLayout } from "./login-layout"
import { useTheme } from "./contexts/theme-context"

export const AppContainer = () => {
    const { isAuthenticated } = useAuth()
    const {darkMode, compactMode} = useTheme()

    const themeAlgorithms = [];
  
    if (darkMode) {
      themeAlgorithms.push(theme.darkAlgorithm);
    }
    
    if (compactMode) {
      themeAlgorithms.push(theme.compactAlgorithm);
    }
    
    return <ConfigProvider
        theme={{
            algorithm: themeAlgorithms,
            token: {
                colorPrimary: '#f5be4a',
                colorLink: '#f5be4a',
                fontFamily: 'Barlow',
            },
        }}
    >
        {isAuthenticated ? <AppLayout /> : <LoginLayout />}
    </ConfigProvider>
}