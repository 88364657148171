import { useEffect, useState } from "react"
import { Table } from 'antd'
import { apiClient } from "../../api/client"
import { objectToQueryString } from "../../utilities/convert-to-query-string"
import {Space } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc'

dayjs.extend(utc)



export const ErrorLogsTable = ({ params, canSelectRows, onUpdateSelectedKeys }) => {

    const [loading, setLoading] = useState(false)
    const [errorLogs, setErrorLogs] = useState()

    const [pageOptions, setPageOptions] = useState({
        pageSize: 50,
        current: 1,
        total: 0,
    })

    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const fetchData = (queryParams) => {

        setLoading(true)
        apiClient.get(`/error-logs?${objectToQueryString(queryParams)}`)
            .then(res => {
                setErrorLogs(res.data?.data)
                setPageOptions({ ...pageOptions, total: res.data.count })
            }).catch((e) => {
                console.log(e)
            })
            .finally(() => setLoading(false))
    }


    const handleTableChange = (nexPagination) => {
        setPageOptions({ ...pageOptions, current: nexPagination.current })
    }

    const handleOnRowSelect = (value) => {
        setSelectedRowKeys(value)
        onUpdateSelectedKeys(value)
    }
    useEffect(() => {
        fetchData({ pageSize: pageOptions.pageSize, pageNumber: pageOptions.current, ...params })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageOptions.current])

    useEffect(() => {
        fetchData({ pageSize: pageOptions.pageSize, pageNumber: 1, ...params })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])



    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            render : (date) => dayjs.utc(date).local().format('YYYY-MM-DD HH:mm'),
            width: 150,
        },
        {
            title: 'id',
            width: 100,
            dataIndex: 'id'
        }, {
            title: 'type',
            dataIndex: 'type',
            width: 150,
        }, {
            title: 'Description',
            dataIndex: 'description',
            width: 400,
            ellipsis: true,
        }]

    const rowSelection = {
        hideSelectAll: true,
        selectedRowKeys,
        onChange: handleOnRowSelect,
    }

    const optionalProps = {
        ...(canSelectRows && rowSelection ? { rowSelection } : {})
    };

    return (
        <Space direction="vertical">
            <Table
                size="small"
                rowKey={'id'}
                loading={loading}
                dataSource={errorLogs}
                columns={columns}
                onChange={handleTableChange}
                pagination={{ pageSize: pageOptions.pageSize, total: pageOptions.total, current: pageOptions.current, showSizeChanger: false, disabled: loading }}
                scroll={{ y: '55vh' }}
                {...optionalProps}
            />
        </Space>)
}