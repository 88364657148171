import { useEffect, useState } from "react"
import { PageContent } from "../components/page-content/page-content"
import { PurchaseOrderSuggestions } from "../components/purchase-order-suggestions/purchase-order-suggestions"
import { Button, Form, Input, Modal, Space, notification } from "antd"
import { apiClient } from "../api/client"
import { useNavigate, useParams } from "react-router-dom"
import { StatusTag } from "../components/status-tag/status-tag"
import { ProductsTable } from "../components/products-table/products-table"
import { ProductsFilters } from "../components/products-filters/products-filters"
import { saveAs } from 'file-saver'


const AddProductModalButton = ({ purchaseOrder }) => {
    const [visible, setVisible] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    // const [queryParams, setQueryParams] = useState({ supplierId: purchaseOrder?.supplier?.id })
    const [queryParams, setQueryParams] = useState()

    const handleAddProducts = async () => {
        const promises = selectedProducts.map(async (product) => {
            debugger;
            console.log(product)
            await apiClient.post('/purchase-order-line-items', {
                purchaseOrderId: purchaseOrder.id,
                productId: product.id,
                quantity: product.minimumReorderAmount > 0 ? product.minimumReorderAmount : 5,
            });
        });

        await Promise.all(promises);

        setVisible(false);
        window.location.reload()
    };



    const handleOnFiltersChange = (nextFilters) => {
        setQueryParams({ ...queryParams, ...nextFilters })
    }


    return <>
        <Button type='primary' ghost onClick={() => setVisible(true)}>Add Item</Button>
        <Modal
            title={'Products'}
            style={{ minWidth: '1200px' }}
            open={visible}
            okText="Add"
            okButtonProps={{ disabled: !selectedProducts.length }}
            onOk={handleAddProducts}
            onCancel={() => setVisible(false)}>
            <Space size='large' direction="vertical">
                <ProductsFilters 
                    options={{ supplier: false}} 
                    onFiltersChange={handleOnFiltersChange} />
                <ProductsTable
                    params={queryParams}
                    canSelectRows={true}
                    onUpdateSelectedKeys={(_values, selectedProducts) => {
                        debugger;
                        setSelectedProducts(selectedProducts)
                        }} />
            </Space>
        </Modal>
    </>
}

const PurchaseOrderActions = ({ purchaseOrder, onRefetch }) => {
    const [showPublishForm, setShowPublishForm] = useState(false)
    const [reference, setReference] = useState(null)
    const navigate = useNavigate()

    const handlePublishPurchaseOrder = async () => {
        const response = await apiClient.put('/purchase-orders/publish', { id: purchaseOrder.id, reference })
        if (response) {
            setShowPublishForm(false)
            onRefetch()
        }
    }

    const getFileName = () => {
        switch (purchaseOrder.supplier.name.toLowerCase()) {
            case 'powerbody': return `${purchaseOrder.supplier.name}-${purchaseOrder.id}.csv`
            case 'prometeus': return `${purchaseOrder.supplier.name}-${purchaseOrder.id}.txt`
            case 'vitamin360': return `${purchaseOrder.supplier.name}-${purchaseOrder.id}.csv`
            case 'dsn-group': return `${purchaseOrder.supplier.name}-${purchaseOrder.id}.csv`
            case 'swedishsupplements': return `${purchaseOrder.supplier.name}-${purchaseOrder.id}.csv`
            case 'biou': return `${purchaseOrder.supplier.name}-${purchaseOrder.id}.csv`
        }
    }

    const handleExportPurchaseOrder = async () => {
        const response = await apiClient.get(`purchase-orders/${purchaseOrder.id}/export`)
        if (response) {
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' })
            saveAs(blob, getFileName())
        }
    }

    const handleConfirmRemovePurchaseOrder = async () => {
        await apiClient.delete(`purchase-orders/${purchaseOrder.id}`).then(() => {
            navigate('/purchase-orders')
        }).catch((e) => {
            notification.error('Somethin went wrong while removing purchase order')
        })

    }

    const handleRemovePurchaseOrder = () => {
        Modal.confirm({ title: 'Are you sure you want to delete this purchase order?', onOk: handleConfirmRemovePurchaseOrder })
    }


    return <Space>
        {purchaseOrder.status !== 'created' &&
            <AddProductModalButton
                purchaseOrder={purchaseOrder}
                onRefetch={() => onRefetch()} />}
        {purchaseOrder.status === 'draft' && <Button type='primary' onClick={() => setShowPublishForm(true)}>Pulish Purchase Order</Button>}
        {<Button type='primary' onClick={handleExportPurchaseOrder} >Export</Button>}
        <Modal title="Publish Purchase Order" open={showPublishForm} onCancel={() => setShowPublishForm(false)} onOk={handlePublishPurchaseOrder}>
            <Form>
                <Form.Item label="Reference Number">
                    <Input onChange={(e) => setReference(e.target.value)} />
                </Form.Item>
            </Form>
        </Modal>
        <Button danger ghost onClick={handleRemovePurchaseOrder} >
            Delete
        </Button>
    </Space>
}

const PurchaseOrderTitle = ({ purchaseOrder }) => <Space> Purchase Order:{purchaseOrder.reference} - {purchaseOrder.supplier.name} <StatusTag status={purchaseOrder.status} /></Space>

export const CreatePurchaseOrder = () => {
    const [purchaseOrder, setPurchaseOrder] = useState()
    const params = useParams()



    const fetchPurchaseOrder = async () => {
        if (params.id) {
            const purchaseOrderResponse = await apiClient.get(`/purchase-orders/${params.id}`)
            setPurchaseOrder(purchaseOrderResponse.data)
        }
    }

    useEffect(() => {
        fetchPurchaseOrder()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!purchaseOrder) {
        return null
    }

    return <PageContent
        title={<PurchaseOrderTitle purchaseOrder={purchaseOrder} />}
        subTitle={`Here you can see purchase order suggestions and create them`}
        actions={<PurchaseOrderActions purchaseOrder={purchaseOrder} onRefetch={() => fetchPurchaseOrder()} />}
    >
        <PurchaseOrderSuggestions purchaseOrderId={purchaseOrder.id} disabled={purchaseOrder.status === 'created'} />
    </PageContent>
}