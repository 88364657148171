import './App.css';
import i18n from './i18n';
import { ThemeProvider } from './contexts/theme-context';
import { I18nextProvider } from 'react-i18next';
import { AuthProvider } from './contexts/auth-context';
import { AppContainer } from './app-container';


function App() {

  return (
    <div className="app">
      <AuthProvider>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider>
            <AppContainer/>
          </ThemeProvider>
        </I18nextProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
