import { Tag } from "antd"
import { useTranslation } from "react-i18next";


export const StatusTag = ({status}) => {
    const {t} = useTranslation()

    switch (status) {
      case 'draft':
          return <Tag color="orange">{t('enums.status.draft')}</Tag>;
      case 'created':
          return <Tag color="green">{t('enums.status.created')}</Tag>;
      case 'wmsNotified':
          return <Tag color="blue">{t('enums.status.wmsNotified')}</Tag>;
      case 'wmsCancelled':
          return <Tag color="blue">{t('enums.status.wmsCancelled')}</Tag>;
      case 'cancelled':
          return <Tag color="red">{t('enums.status.cancelled')}</Tag>;
      case 'wmsArrival':
          return <Tag color="purple">{t('enums.status.wmsArrival')}</Tag>;
      case 'wmsDeflection':
          return <Tag color="error">{t('enums.status.wmsDeflection')}</Tag>;
      case 'wmsReceived':
          return <Tag color="gold">{t('enums.status.wmsReceived')}</Tag>;
      case 'wmsInbound':
          return <Tag color="cyan">{t('enums.status.wmsInbound')}</Tag>;
      default:
          return <Tag>{t('enums.status.unknown')}</Tag>;
  }
}