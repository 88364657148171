import { useEffect, useState } from "react"
import { apiClient } from "../api/client"
import { Modal, Switch, Table, notification } from 'antd'
import { PageContent } from '../components/page-content/page-content'


export const Suppliers = () => {

    const [loading, setLoading] = useState(false)
    const [suppliers, setSuppliers] = useState()

    const handleDeactivateSupplier = async (supplier) => {
        try {
            await apiClient.put('/suppliers/deactivate', { id: supplier.id })
            fetchData()
        }
        catch (e) {
            notification.error({message: 'An error occured when trying to de-activate supplier'})
        }
    }

    const handleActivateSupplier = async (supplier) => {
        try {
            await apiClient.put('/suppliers/activate', { id: supplier.id })
            fetchData()
        }
        catch (e) {
            notification.error({message: 'An error occured when trying to activate supplier'})
        }
    }
    const handleSwitchActivate = async (supplier) => {
        if (supplier.is_active === true) {
            Modal.confirm({
                title: 'Important Note',
                onOk: () => handleDeactivateSupplier(supplier),
                content: `By deactivating this supplier the inventory will be re-adjusted and exclude this supplier's stock numbers`
            })
        }
        else {
            Modal.confirm({
                title: 'Important Note',
                onOk: () => handleActivateSupplier(supplier),
                content: `By activating this supplier the inventory will be re-adjusted and include this supplier's stock numbers`
            })
        }
    }


    const columns = [
        {
            title: 'ID',
            width: 50,
            dataIndex: 'id',
        },
        {
            title: 'Name',
            width: 150,
            dataIndex: 'name',
        }, {
            title: 'Active',
            width: 100,
            render: (_text, row) => <Switch onClick={() => handleSwitchActivate(row)} checked={row.is_active} />,
            align: 'right',
            dataIndex: 'id'

        }]

    const fetchData = () => {
        setLoading(true)
        apiClient.get(`/suppliers`)
            .then(res => {
                setSuppliers(res.data)

            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line 
    }, [])

    return (
        <PageContent
            title='Suppliers'
        >
            <Table
                size="small"
                rowKey={'id'}
                loading={loading}
                dataSource={suppliers}
                columns={columns}
                scroll={{ y: '60vh' }} />
        </PageContent>)
}
